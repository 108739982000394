<template>
    <div>
        <select v-model="comp.info.category" class="w-40per" @change="comp.setSubForm">
            <option value="">구분 선택</option>
            <option v-for="(cat, idx) in comp.forminfo" :key="idx" :value="cat.category">{{cat.category}}</option>            
        </select>
        <select v-model="comp.info.formname" class="w-50per ml-10">
            <option value="">양식 선택</option>
            <option v-for="(form, idx) in comp.subinfo" :key="idx" :value="form.formname">{{form.formname}}</option>
        </select>
    </div>
</template>
<script>
import { onMounted, reactive } from 'vue'
import axios from '@/plugins/axios.js'

export default {
    name: 'DocumentFormSelector',
    components: {
    },
    props: {
        modelValue: {
            type: Object,
        },
    },
    emits: [ 'update:modelValue' ],
    setup(props, { emit }) {

        const comp = reactive({
            info : {
                category : "",
                formname : ""
            },
            forminfo : [],
            subinfo : {},

            setSubForm : () => {
                comp.info.formname = "";
                for(let i=0;i<comp.forminfo.length;i++) {
                    if(comp.forminfo[i].category == comp.info.category) {
                        comp.subinfo = comp.forminfo[i].list;
                    }
                }
                emit('update:modelValue', comp.info);
            },

            getDocumentForm : () => {
                let params = {
                    tgdate : new Date(),
                 };
                axios.get('/rest/documents/getForms', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        comp.forminfo = res.data.forms;
                    } else {
                        console.info(res.data.err_msg);
                    }
                })
            },

            bank : "",
            banklist : [
                "신한은행", "국민은행", "농협은행", "수협은행", "우리은행", "한국씨티은행", "케이뱅크은행", "제주은행", "기업은행", "산업은행", "신협은행", "하나은행", "카카오뱅크"
            ],
            update: () => {
                emit('update:modelValue', comp.bank);
            }
        });

        onMounted(() => {
            // Mounted
            comp.getDocumentForm();
        });

        return {comp};
    }
}
</script>
<style lang="scss" scoped>
.bank-select {
    width: 150px;
}
</style>