<template>
  <!-- 서브페이지 내용 -->
  <div class="con-wrap">
    <CarrotTitle title="결재 리스트">
      - 결재 제목 클릭 시 상세페이지로 이동합니다.<br />
      - 결재 리스트는 등록일 최근 순으로 정렬됩니다.
    </CarrotTitle>
    <div class="board">
      <div class="mt-40">
        <div>
          <table class="table-row table-serach">
            <tbody>
              <tr>
                <th width="180">등록일</th>
                <td>
                  <CarrotDatePicker
                    class="inline-datepicker"
                    v-model="search.info.sdate"
                  ></CarrotDatePicker>
                  ~
                  <CarrotDatePicker
                    class="inline-datepicker"
                    v-model="search.info.edate"
                  ></CarrotDatePicker>
                </td>
                <th width="180">문서번호</th>
                <td>
                  <input
                    type="text"
                    class="w-100per"
                    v-model="search.info.doc_num"
                  />
                </td>
              </tr>
              <tr>
                <th width="180">양식</th>
                <td>
                  <DocumentFormSelector
                    v-model="search.temp.forms"
                  ></DocumentFormSelector>
                </td>
                <th width="180">기안자</th>
                <td>
                  <CarrotStaff
                    :idx_office="search.info.idx_office"
                    :idx_team="search.info.idx_team"
                    v-model="search.info.idx_staff"
                  ></CarrotStaff>
                </td>
              </tr>
              <tr>
                <th width="180">부문/본부</th>
                <td>
                  <CarrotSector
                    v-model="search.info.idx_sector"
                    class="w-200px"
                  ></CarrotSector>
                  <CarrotDept
                    :idx_sector="search.info.idx_sector"
                    v-model="search.info.idx_office"
                  ></CarrotDept>
                </td>
                <th width="180">팀</th>
                <td>
                  <CarrotTeam
                    :idx_sector="search.info.idx_sector"
                    :idx_office="search.info.idx_office"
                    v-model="search.info.idx_team"
                  ></CarrotTeam>
                </td>
              </tr>
              <tr>
                <th width="180">제목</th>
                <td>
                  <input
                    type="text"
                    class="w-100per"
                    v-model="search.info.title"
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <button
            class="btn-default float-right mt-10"
            @click="docs.getDocuments()"
          >
            검색
          </button>
          <div class="clear"></div>

          <div class="mt-40 mb-20">
            <select
              class="w-200px"
              v-model="search.info.doc_state"
              @change="docs.getDocuments()"
            >
              <option value="">전체</option>
              <option value="대기">대기</option>
              <option value="진행중">진행중</option>
              <option value="완료">완료</option>
              <option value="반려">반려</option>
            </select>
            <select
              class="w-200px ml-20"
              v-model="search.info.doc_type"
              @change="docs.getDocuments()"
            >
              <option value="">전체</option>
              <option value="T1">내가 결재할 문서</option>
              <option value="T2">내가 결재한 문서</option>
              <option value="T5">내가 참조된 문서(미확인)</option>
              <option value="T3">내가 참조된 문서</option>
              <option value="T4">내가 기안한 문서</option>
            </select>
            <router-link to="DraftingAdd"
              ><button class="btn-default float-right">
                기안작성
              </button></router-link
            >
            <div class="clear"></div>
          </div>

          <table class="table-col">
            <colgroup>
              <col width="55" />
              <col width="100" />
              <col width="100" />
              <col width="100" />
              <col width="130" />
              <col width="300" />
              <col width="110" />
              <col width="*" />
              <col width="*" />
              <col width="*" />
            </colgroup>
            <thead>
              <tr>
                <th>No</th>
                <th>등록일</th>
                <th>진행상태</th>
                <th>문서번호</th>
                <th>양식</th>
                <th>제목</th>
                <th>기안자</th>
                <th>부문</th>
                <th>본부</th>
                <th>팀</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="docs.list.length == 0">
                <td colspan="9">자료가 없습니다.</td>
              </tr>
              <tr v-for="d in docs.list" :key="d.idx">
                <td>{{ d.num }}</td>
                <td>{{ d.cdate }}</td>
                <td>{{ d.status }}</td>
                <td>
                  <p class="line-clamp-1">{{ d.code }}</p>
                </td>
                <td>
                  <p class="line-clamp-1">{{ d.form }}</p>
                </td>
                <td>
                  <router-link
                    target="_blank"
                    :to="'/documents/Document/' + d.idx"
                    class="table-title"
                  >
                    <span class="txt-left line-clamp-1">{{ d.title }}</span>
                    <span class="list-num ml-5" v-if="d.cnt_comment > 0"
                      >({{ d.cnt_comment }})</span
                    >
                  </router-link>
                </td>

                <td>
                  <p class="line-clamp-1">{{ d.ename }}({{ d.kname }})</p>
                </td>
                <td>
                  <p class="line-clamp-1">{{ d.sector_name }}</p>
                </td>
                <td>
                  <p class="line-clamp-1">{{ d.office_name }}</p>
                </td>
                <td class="txt-ellipsis">
                  <p class="txt-ellipsis">{{ d.team_name }}</p>
                </td>
              </tr>
            </tbody>
          </table>

          <CarrotPaging
            v-if="docs.total > 0"
            :total="docs.total"
            :list_per_page="50"
            v-model="search.info.page"
            @change="docs.getDocuments()"
          ></CarrotPaging>
          <div class="clear"></div>
        </div>
      </div>
    </div>
  </div>
  <!-- 서브페이지 내용 끝 -->
</template>

<script>
// @ is an alias to /src
import { onMounted, onUnmounted, reactive } from "vue";
import axios from "@/plugins/axios.js";
import CarrotDatePicker from "@/components/common/CarrotDatePicker.vue";
import CarrotDept from "@/components/common/CarrotDept.vue";
import CarrotSector from "@/components/common/CarrotSector";
import CarrotTeam from "@/components/common/CarrotTeam.vue";
import CarrotStaff from "@/components/common/CarrotStaff.vue";
import DocumentFormSelector from "@/components/input/DocumentFormSelector.vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { onBeforeRouteUpdate } from "vue-router";

export default {
  layout: "documents",
  components: {
    CarrotDatePicker,
    CarrotDept,
    CarrotTeam,
    CarrotStaff,
    DocumentFormSelector,
    CarrotSector,
  },
  props: {
    tp: {
      type: String,
      default: "",
    },
  },

  setup() {
    const route = useRoute();
    const store = useStore();

    const search = reactive({
      info: {
        page: 1,
        sdate: "",
        edate: "",
        idx_sector: 0,
        idx_office: 0,
        idx_team: 0,
        idx_staff: 0,
        doc_state: "",
        doc_type: "",
        doc_num: "",
        title: "",
      },
      init: false,
      temp: {
        forms: {
          category: "",
          formname: "",
        },
      },
      dateToString(ndate) {
        return [
          ndate.getFullYear(),
          (ndate.getMonth() + 1 > 9 ? "" : "0") + (ndate.getMonth() + 1),
          (ndate.getDate() > 9 ? "" : "0") + ndate.getDate(),
        ].join("-");
      },
      getDocumentForm: () => {
        let params = {
          tgdate: new Date(),
        };
        axios
          .get("/rest/documents/getForms", { params: params })
          .then((res) => {
            if (res.data.err == 0) {
              docs.ca_list = res.data.list;
            } else {
              console.info(res.data.err_msg);
            }
          });
      },
    });

    const docs = reactive({
      list: [],
      total: 0,
      getDocuments: () => {
        if (search.init == false) {
          return;
        }
        search.info.uidx = store.state.idx_staff;
        let params = search.info;
        params.form_cat = search.temp.forms.category;
        params.form_name = search.temp.forms.formname;

        axios
          .get("/rest/documents/getDocuments", { params: params })
          .then((res) => {
            if (res.data.err == 0) {
              docs.list = res.data.list;
              docs.total = res.data.total;
            } else {
              console.info(res.data.err_msg);
            }
          });
      },
      getCheck: (tp) => {
        let cat = "";
        if (tp == "T1") {
          cat = "결재";
        } else if (tp == "P2") {
          cat = "완료";
        } else if (tp == "T3") {
          cat = "참조";
        } else if (tp == "P3") {
          cat = "반려";
        }
        if (cat != "") {
          let params = { cat: cat };

          axios
            .get("/rest/documents/checkIcon", { params: params })
            .then((res) => {
              if (res.data.err == 0) {
                console.info(res.data.err);
              } else {
                console.info(res.data.err_msg);
              }
            });
        }
      },
    });

    onBeforeRouteUpdate(async (to, from) => {
      search.init = false;
      console.info("AA", to.query.doc_type);
      if (to.query.doc_type !== from.query.doc_type) {
        search.info = {
          page: 1,
          sdate: "",
          edate: "",
          idx_office: 0,
          idx_team: 0,
          idx_staff: 0,
          doc_state: "",
          doc_type: "",
          doc_num: "",
        };

        if (to.query.doc_type == "P2") {
          console.info("!", to.query.doc_type);
          search.info.doc_state = "완료";
          search.info.idx_staff = store.state.idx_staff;
        } else if (to.query.doc_type == "P3") {
          search.info.doc_state = "반려";
          search.info.idx_staff = store.state.idx_staff;
        } else {
          search.info.doc_type = to.query.doc_type;
        }
      }

      let today = new Date();
      let sdate = new Date(today.getFullYear(), today.getMonth(), 1);
      let edate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      search.info.sdate = search.dateToString(sdate);
      search.info.edate = search.dateToString(edate);
      search.init = true;
      docs.getDocuments();
    });

    onMounted(() => {
      // Mounted
      if (typeof route.query.doc_type != "undefined") {
        if (route.query.doc_type == "P2") {
          search.info.doc_state = "완료";
          search.info.idx_staff = store.state.idx_staff;
        } else if (route.query.doc_type == "P3") {
          search.info.doc_state = "반려";
          search.info.idx_staff = store.state.idx_staff;
        } else {
          search.info.doc_type = route.query.doc_type;
        }
        docs.getCheck(route.query.doc_type);
      }
      if (typeof route.query.doc_state != "undefined") {
        search.info.doc_state = route.query.doc_state;
      }

      //            console.info(props.tp);
      let today = new Date();
      let sdate = new Date(today.getFullYear(), today.getMonth() - 2, 1);
      let edate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      search.info.sdate = search.dateToString(sdate);
      search.info.edate = search.dateToString(edate);
      search.init = true;
      docs.getDocuments();
    });

    onUnmounted(() => {
      // UnMounted
    });
    return { search, docs };
  },
};
</script>

<style lang="scss" scoped>
.inline-datepicker {
  display: inline-block;
}

.sub-wrap .con-wrap .table-col thead tr th,
.sub-wrap .con-wrap .table-col tbody tr td {
  word-break: normal;
}

.table-title {
  display: flex;
}
</style>
