<template>
    <div class="comp-select">
        <select v-model="comp.idx_office" @change="comp.update">
            <option value="0">부문 전체</option>
            <option :value="irow.idx" v-for="(irow, i) in comp.hq_office" :key="i">{{ irow.kname }}</option>
        </select>
    </div>
</template>

<script>
import { onMounted, reactive, watch } from 'vue'
import axios from 'axios'

export default {
    name: 'CarrotSector',
    props: {
        modelValue: {
            type: [Number, String],
            default: 0,
        }
    },
    emits: [ 'update:modelValue' ],
    setup(props, { emit }) {

        const comp = reactive({
            hq_office  : [],
            idx_office : 0,

            doSearchOffice : () => {
                let params = {};
                axios.get("/rest/component/getSectorList", { params : params}).then((res) => {
                    if( res.data.err == 0 ){
                        comp.hq_office = res.data.list;
                    } else {
                        console.info(res);
                    }
                });
            },
            update: () => {
                emit('update:modelValue', comp.idx_office);
            }

        });

        watch(() => props.modelValue, (newVal, oldVal) => {
            if(newVal != oldVal && comp.idx_office != newVal) {
                comp.idx_office = props.modelValue;
            }
        });

        onMounted(() => {
            // Mounted
            comp.idx_office = props.modelValue;

            comp.doSearchOffice();
        });

        return {comp};
   }
}
</script>
<style lang="scss" scoped>
.comp-select {
    display: inline-block;
    select {
        width: 100%;
        margin-right: 10px;
    }
}
</style>